<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import DatePicker from "vue2-datepicker";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: " Demande user",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "",
          value: 0,
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],

      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      showRefuse:false,
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      attenteData: [],
      refuseData: [],
      fields: [
        { key: "designation", sortable: true, label: "Nom et prénom" },
        /* { key: "date", sortable: true }, */
        { key: "tel", sortable: true, label: "Contact" },
        { key: "email", sortable: true, label: "Email" },
        { key: "ville", sortable: true, label: "Ville de résidence" },
        { key: "action", label: "Actions" },
      ],
      autoEnAttente:[],

      showDisable: false,
      showAccepte: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
      },
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/rmo/particulier",
        undefined,
        false
      );

    
      if (data && data.data) {
        console.log("refused :", data.data);
        const formattedTable = data.data.autoEnAttente.map((demande) => {
          var id = demande.id;
          var designation = `${demande.personne.lastname} ${demande.personne.firstname}`;
          var email = demande.personne.email;
          var tel = `${demande.personne.indicatifTel} ${demande.personne.tel}`;
          var ville = demande.personne.ville;
          return {
            id: id,
            designation: designation,
            email: email,
            tel: tel,
            ville: ville,
          };
        });
        this.autoEnAttente = formattedTable;
      }
      const formattedTableRefuse = data.data.autoRefuse.map((refuseData)=>{
         var id = refuseData.id;
          var designation = `${refuseData.personne.lastname} ${refuseData.personne.firstname}`;
          var email = refuseData.personne.email;
          var tel = `${refuseData.personne.indicatifTel} ${refuseData.personne.tel}`;
          var ville = refuseData.personne.ville;
          return {
            id: id,
            designation: designation,
            email: email,
            tel: tel,
            ville: ville,
          };
      }
    
      );
        this.refuseData=formattedTableRefuse
    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.showSucess = true;
                this.$snotify.success("Message envoyé avec succès", "Fait");
        this.init();
        console.log(data);
      } else {
        this.showEchec = true;
        this.init();
        console.log(data);
      }
    },

    async ModifSubmit() {
      this.showEdit = false;
     
      const Editdata = await apiRequest(
        "PUT",
        "users/" + this.infoUser.id,
        {
          idUser: this.infoUser.id,
          lastname: this.infoUser.lastname,
          firstname: this.infoUser.firstname,
          tel: this.infoUser.telephone,
          email: this.infoUser.email,
          adresse: this.infoUser.adresse,
          ville: this.infoUser.ville,
          pays: this.infoUser.pays,
          name: this.infoUser.name,
          boitePostal: this.infoUser.boitePostal,
          birthday: this.infoUser.birthday,
          userType: this.userTypeSelect,
          profession: this.infoUser.profession,
          apropos: this.infoUser.apropos,
          permisCategorie: this.infoUser.permisCategorie,
          permisAnnee: this.infoUser.permisNumuero,
          permisNumuero: this.infoUser.permisNumuero,
          personneUrgence: this.infoUser.contactUrgence,
          contactUrgence: this.infoUser.contactUrgence,
        },
        false
      );

      if (Editdata && Editdata.data) {
        this.showSucessEdit = true;
        this.init();

      } else {
        this.showEchecEdit = true;
        this.init();

      }

    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;
     

      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
      
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy hh:mm:ss"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.personne.userType;
      }

     
     
    },
  
    acceptDialog(row){
     this.userToUse = row.id;
     
      this.showAccepte=true;
    
      

    },

        refuseDialog(row){
     this.userToUse = row.id;
      this.showRefuse=true;
    },

    async accepter() {     
      const data = await apiRequest(
        "POST",
        "admin/rmo/particulier/action",
        {
          user: this.userToUse,
          actif: true,
        },
        false
      );
       
      if (data && data.data) {
          this.showAccepte=false;
         this.$snotify.success('Fait!','Demande acceptée avec succès'); 
        this.init();
      }
    },
    async refuser() {
    
      const data = await apiRequest(
       "POST",
        "admin/rmo/particulier/action",
        {
          user: this.userToUse,
          actif: false,
        },
        false
      );
     
      if (data && data.data) {
           this.showRefuse=false;
       this.$snotify.success('Fait!','Demande rejetée avec succès'); 
        this.init();
      }
    },
    async desactiveUser() {
      this.showAccepte = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
     
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;
   
      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
     
    },
    async activeUser(row) {
      this.userToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );
     
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {

      /*  router.push(`/agence/details/${row}`); */
      router.push("/user/user_info/" + row.id);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">En attente</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                   
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="autoEnAttente"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="
                          py-2
                          d-flex
                          justify-content-center
                          badge
                          font-size-12
                        "
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click=" refuseDialog(row.item)"
                        title="Refuser"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="acceptDialog(row.item)"
                        v-b-tooltip.hover
                        title="Accepter"
                      >
                        <i class="fas fa-check"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Refusées</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                  
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="refuseData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="
                          py-2
                          d-flex
                          justify-content-center
                          badge
                          font-size-12
                        "
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="acceptDialog(row.item)"
                        v-b-tooltip.hover
                        title="Accepter"
                      >
                        <i class="fas fa-check"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showAccepte"
      title="Voulez-vous vraiment accepter cette demande ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showAccepte = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="accepter"
          >Oui</b-button
        >
      </div>
    </b-modal>
      <b-modal
      id="modal-backdrop"
      centered
      v-model="showRefuse"
      title="Voulez-vous vraiment rejeter cette demande ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showRefuse = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="refuser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="Voulez-vous vraiment désactiver cette demande ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Agence modifiée avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="La modification a échouée"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer véhicule"
      title-class="font-18"
      hide-footer
    >
      <form enctype="multipart/form-data" @submit.prevent="submitCar">
        <div class="row">
          <div class="col-md-6">
            <label for="">Nom de l'agence</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Nom du dirigeant</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">RCCM</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Prénom</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">IFU</label>
            <input type="text" class="form-control" id="" />
            <!-- <multiselect
                    v-model="motorisationSelect"
                    :options="motorisation"
                    label="name"
                    track-by="name"
                  ></multiselect> -->
          </div>
          <div class="col-md-6">
            <label for="">Contact</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Contact</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Email</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6 mt-4">
            <label for="">Nombre de véhicule</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6 mt-4">
            <label for="">Mot de passe</label>
            <input type="text" class="form-control" id="" />
           
          </div>
        </div>
        <div class="form-group mt-4">
          <button class="btn btn-primary float-right">Modifier</button>
        </div>
      </form>
    </b-modal>


    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
        
          <textarea
            v-model="messageContent.message"
            placeholder="Votre message"
            class="form-control"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>
